export enum RuleId {
  PlaceRegion = 1,
  RevealRegions,
  DealSanctuaries,
  ChooseNewRegion,
  PlaceSanctuary,
  RefillRegion,
  HideRegionLine,
  Scoring,
  ChooseHandCards
}
